<template>
   <div>自动续费</div>
</template>

<script>
export default {
  name: "takeRecharge"
}
</script>

<style scoped>

</style>